import React from "react";
import { Link } from "react-router-dom";
import { RiEmotionSadLine } from "react-icons/ri";

interface Props {
  message?: string;
}

export default function NotFound({
  message = "We couldn't find the product you're looking for.",
}: Props) {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background text-center px-4">
      <RiEmotionSadLine className="text-6xl text-black mb-4" />
      <h1 className="text-3xl font-bold mb-2">Oops! Product Not Found</h1>
      <p className="text-xl text-gray-600 mb-8">{message}</p>
      <Link
        to="/products"
        className="bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-800 transition duration-300"
      >
        Explore products
      </Link>
    </div>
  );
}
