import { useEffect, useState } from "react";
import { Product } from "../lib/types";
import { db } from "../lib/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import Grid from "../components/products/Grid";
import { Link, useOutletContext } from "react-router-dom";
import { OutletContext } from "../App";

// TODO: maybe we keep thumbnails so people can see that some liked products were sold?
const LikedPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [likedProducts, setLikedProducts] = useState<Product[]>([]);
  const { setSelectedProducts } = useOutletContext<OutletContext>();
  const fetchLikedProducts = async () => {
    const likedProductIds =
      JSON.parse(localStorage.getItem("likedProducts") || "[]") || [];
    if (likedProductIds.length > 0) {
      setLoading(true);
      let q = query(collection(db, "products"));
      q = query(q, where("id", "in", likedProductIds));

      const snapshot = await getDocs(q);
      const products = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Product[];

      setLikedProducts(products);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLikedProducts();
  }, []);

  return (
    <div className="container mx-auto pb-16 px-4">
      <h1 className="text-3xl font-serif mt-4 mb-4 text-center">
        Your saved products
      </h1>
      {!loading && likedProducts.length === 0 ? (
        <div>
          <h1 className="text-xl font-serif mt-8 mb-4 text-center">
            You have no saved products yet
          </h1>
          <div className="flex justify-center mt-8 mb-4">
            <Link to="/products">
              <div className="bg-black  text-white rounded-md p-2 px-4 font-sans">
                Start exploring
              </div>
            </Link>
          </div>
        </div>
      ) : (
        <Grid products={likedProducts} loading={loading} numProducts={8} />
      )}
    </div>
  );
};

export default LikedPage;
