import { Product } from "../../lib/types";
import Button from "../common/Button";
import BuyButton from "../common/BuyButton";

export default function BuyFooter({ product }: { product: Product }) {
  return (
    <div className="fixed shadow-[0px_0px_5px_0px_#00000024] h-24 bottom-0 left-0 w-full bg-white p-4 flex justify-center items-center z-50">
      <BuyButton product={product} />
    </div>
  );
}
