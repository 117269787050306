import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import ProductsPage from "./pages/ProductsPage";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Link,
  Route,
  RouterProvider,
} from "react-router-dom";
import ProductPage from "./pages/ProductPage";
import LikedPage from "./pages/LikedPage";
import App from "./App";
import BrandPage from "./pages/BrandPage";
import LandingPage from "./pages/LandingPage";
import React from "react";
import Button from "./components/common/Button";
import LeavingPage from "./pages/LeavingPage";

const RedirectToExternal = ({ to }: { to: string }) => {
  useEffect(() => {
    window.location.replace(to);
  }, []);

  return null;
};

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-96px)] bg-background ">
      <h1 className="text-6xl font-bold mb-4">404</h1>
      <h2 className="text-2xl font-semibold mb-4">Page Not Found</h2>
      <p className="text-lg mb-6">
        Oops! The page you are looking for does not exist.
      </p>
      <Link to="/">
        <Button title={"Go back to home"} onClick={() => {}} />
      </Link>
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<LandingPage />} />
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/product/:productId" element={<ProductPage />} />
      <Route path="/liked" element={<LikedPage />} />
      <Route path="/brands" element={<BrandPage />} />
      <Route
        path="/blog"
        element={<RedirectToExternal to="https://blog.avenura.com" />}
      />
      <Route
        path="/partners"
        element={<RedirectToExternal to="https://blog.avenura.com/partners" />}
      />
      <Route path="/leaving" element={<LeavingPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);
