import React from "react";

interface Props {
  value: number;
  decimals?: boolean;
}

export default function Percentage({ value, decimals = false }: Props) {
  const locale = navigator.language;

  const formattedPercentage = new Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
  }).format(value);

  return <span>{formattedPercentage}</span>;
}
