import React, { useEffect, useState } from "react";
import { Product } from "../lib/types";
import Carousel from "./Carousel";
import { RiArrowDownSLine, RiArrowUpSLine, RiZoomInLine } from "react-icons/ri";
import Price from "./Price";
import PriceRating from "./PriceRating";
import BuyButton from "./common/BuyButton";

const TruncatedText = ({ text }: { text: string }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldTruncate = text.length > 100;

  if (!shouldTruncate) {
    return <p className="font-sans text-md">{text}</p>;
  }

  function formatDescription(description: string) {
    return description.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  return (
    <div>
      <p className="font-sans text-md">
        {isExpanded ? (
          formatDescription(text)
        ) : (
          <>
            {formatDescription(text.slice(0, 100))}
            ...
          </>
        )}
      </p>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center gap-1  text-sm mt-1"
      >
        {isExpanded ? (
          <>
            Show less <RiArrowUpSLine className="text-lg" />
          </>
        ) : (
          <>
            Read more <RiArrowDownSLine className="text-lg" />
          </>
        )}
      </button>
    </div>
  );
};

const ImageWithSkeleton = ({
  src,
  onClick,
}: {
  src: string;
  onClick: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="relative w-full aspect-square">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse"></div>
      )}
      <img
        src={src}
        className={`object-cover w-full h-full aspect-square cursor-pointer scale-[1.01] ${
          isLoading ? "opacity-0" : "opacity-100"
        }`}
        onLoad={() => setIsLoading(false)}
        onClick={onClick}
      />
    </div>
  );
};

interface Props {
  products: Product[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  handleClose: () => void;
}

const Compare = ({ products, setSelectedProducts, handleClose }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const [carouselImages, setCarouselImages] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleClickImage = (images: string[], index: number) => {
    setCarouselImages(images);
    setCurrentIndex(index);
    setShowCarousel(true);
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const close = () => {
    setIsVisible(false);
    setTimeout(() => {
      handleClose();
    }, 300);
  };

  const clearAll = () => {
    setIsVisible(false);
    setSelectedProducts([]);
    handleClose();
  };

  // Define the rows (previously columns) for our transposed table
  const rows = [
    {
      label: "",
      render: (product: Product) => (
        <div className="flex justify-center items-center">
          <div className="w-48 h-48 flex items-center justify-center aspect-square">
            <div className="relative w-48 h-48 aspect-square">
              <ImageWithSkeleton
                src={product.thumbnailUrl}
                onClick={() =>
                  handleClickImage([product.imageUrl, ...product.imageUrls], 0)
                }
              />
              <div className="absolute bottom-2 right-2">
                <RiZoomInLine className="text-2xl text-black" />
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "",
      render: (product: Product) => (
        <div className="flex justify-center items-center">
          {product.sold ? (
            <p>Sold out :(</p>
          ) : (
            <BuyButton product={product} mods="text-xs sm:text-sm" />
          )}
        </div>
      ),
    },
    {
      label: "Price",
      render: (product: Product) => (
        <div>
          {product.discount ? (
            <p className="font-sans text-md">
              <span className="text-discount mr-2">
                <Price amount={product.discount} currency={product.currency} />
              </span>
              <span className="text-gray-500 line-through decoration-1">
                <Price amount={product.price} currency={product.currency} />
              </span>
            </p>
          ) : (
            <Price amount={product.price} currency={product.currency} />
          )}
        </div>
      ),
    },
    {
      label: "Price Rating",
      render: (product: Product) => <PriceRating product={product} />,
    },
    {
      label: "Brand",
      render: (product: Product) => (
        <p className="font-sans text-md">{product.brand}</p>
      ),
    },
    {
      label: "Model",
      render: (product: Product) => (
        <p className="font-sans text-md">{product.model}</p>
      ),
    },
    {
      label: "Condition",
      render: (product: Product) => (
        <div>
          <p className="font-sans text-md">{product.condition}</p>
          {product.conditionDescription && (
            <p className="font-sans text-md mt-2">
              {product.conditionDescription}
            </p>
          )}
        </div>
      ),
    },
    {
      label: "Color",
      render: (product: Product) => (
        <p className="font-sans text-md">{product.color ?? "N/A"}</p>
      ),
    },
    {
      label: "Material",
      render: (product: Product) => (
        <p className="font-sans text-md">{product.material ?? "N/A"}</p>
      ),
    },
    {
      label: "Dimen- sions",
      render: (product: Product) => (
        <div className="grid grid-cols-[3em_4em_auto] font-sans text-md">
          <p>Length</p>
          <p className="text-right">{product.length} cm</p>
          <p></p>
          <p>Width</p>
          <p className="text-right">{product.width} cm</p>
          <p></p>
          <p>Height</p>
          <p className="text-right">{product.height} cm</p>
          <p></p>
        </div>
      ),
    },
    {
      label: "Descript- tion",
      render: (product: Product) => (
        <div>
          <TruncatedText
            text={product.description ?? "Description not available"}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="fixed inset-0 z-50 flex justify-center items-end">
        <div
          className={`relative w-full h-full bg-background overflow-hidden transition-transform duration-300 ease-out ${
            isVisible ? "translate-y-0" : "translate-y-full"
          }`}
        >
          {/* Fixed Header */}
          <div className="fixed h-16 top-0 left-0 w-full bg-white z-40 py-4 px-4 shadow flex items-center justify-between">
            <div className="flex items-center space-x-2" onClick={close}>
              <RiArrowDownSLine className="text-2xl" />
              <h1 className="text-lg font-bold">Compare Products</h1>
            </div>
            <button onClick={clearAll} className="underline">
              Clear All
            </button>
          </div>

          {/* Table container */}
          <div className="mt-16 h-[calc(100vh-4rem)] overflow-auto overscroll-contain">
            <table className="w-full">
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {/* First column (labels) */}
                    <th
                      className={`p-1 text-left font-bold border-b border-r bg-white sticky left-0 w-16 z-30 shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)] text-xs
            ${rowIndex === rows.length - 1 ? "align-top" : "align-middle"}`}
                    >
                      {row.label}
                    </th>
                    {/* Product columns */}
                    {products.map((product, productIndex) => (
                      <td
                        key={`${rowIndex}-${productIndex}`}
                        className={`p-2 border-b border-r last:border-r-0 ${
                          productIndex % 2 === 0 ? "bg-gray-100" : "bg-white"
                        } ${
                          rowIndex === rows.length - 1
                            ? "align-top"
                            : "align-middle"
                        }`} // Only apply align-top to description row
                      >
                        {row.render(product)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Carousel modal */}
      {showCarousel && (
        <div className="fixed inset-0 bg-background bg-opacity-75 z-50 flex justify-center items-center">
          <div className="relative bg-background w-full h-full mx-auto flex justify-center items-center">
            <button
              className="absolute top-2 right-4 text-black text-4xl z-50"
              onClick={() => setShowCarousel(false)}
            >
              &times;
            </button>
            <Carousel images={carouselImages}></Carousel>
          </div>
        </div>
      )}
    </div>
  );
};

export default Compare;
