import { MutableRefObject, useEffect, useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import {
  collection,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Product } from '../lib/types';
import { HiArrowLongRight } from 'react-icons/hi2';
import Number from '../components/Number';
import Grid from '../components/products/Grid';
import { OutletContext } from '../App';
import { LuSearch, LuSearchX } from 'react-icons/lu';

const useTypingEffect = (examples: string[]) => {
  const [placeholderText, setPlaceholderText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const currentExample = examples[currentIndex];
    let timeout: NodeJS.Timeout;

    if (isTyping) {
      if (placeholderText.length < currentExample.length) {
        timeout = setTimeout(() => {
          setPlaceholderText(
            currentExample.slice(0, placeholderText.length + 1)
          );
        }, 100);
      } else {
        timeout = setTimeout(() => setIsTyping(false), 2000);
      }
    } else {
      if (placeholderText.length > 0) {
        timeout = setTimeout(() => {
          setPlaceholderText(placeholderText.slice(0, -1));
        }, 50);
      } else {
        setCurrentIndex(prevIndex => (prevIndex + 1) % examples.length);
        setIsTyping(true);
      }
    }

    return () => clearTimeout(timeout);
  }, [placeholderText, currentIndex, isTyping, examples]);

  return placeholderText;
};

const BestDealsProducts = ({
  scrollTop,
}: {
  scrollTop: MutableRefObject<number>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      const q = query(
        collection(db, 'products'),
        orderBy('percentageDifference', 'asc'),
        where('sold', '==', false),
        limit(8)
      );
      const querySnapshot = await getDocs(q);
      const products = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as Product[];

      setProducts(products);
      setLoading(false);
    };

    fetchProducts();
  }, []);

  return (
    <section className="bg-background py-8">
      <div className="container mx-auto px-4">
        <Link to="/products?sort=priceDiff" reloadDocument>
          <div className="flex justify-between px-4 items-center">
            <h2 className="text-3xl font-bold font-serif mb-4">Best Value</h2>
            <span className="flex items-center mb-4">
              <h2 className="text-lg font-bold font-serif">View all</h2>
              <HiArrowLongRight className="text-xl ml-2 inline-block" />
            </span>
          </div>
        </Link>
        <Grid products={products} loading={loading} numProducts={8} />
      </div>
    </section>
  );
};

const LatestProducts = ({
  scrollTop,
}: {
  scrollTop: MutableRefObject<number>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      const q = query(
        collection(db, 'products'),
        orderBy('createdAt', 'desc'),
        where('sold', '==', false),
        limit(8)
      );
      const querySnapshot = await getDocs(q);
      const products = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as Product[];

      setProducts(products);
      setLoading(false);
    };

    fetchProducts();
  }, []);

  return (
    <section className="bg-background py-8">
      <div className="container mx-auto px-4">
        <Link to="/products?sort=createdAt" reloadDocument>
          <div className="flex justify-between px-4 items-center">
            <h2 className="text-3xl font-bold font-serif mb-4">New In</h2>
            <span className="flex items-center mb-4">
              <h2 className="text-lg font-bold font-serif">View all</h2>
              <HiArrowLongRight className="text-xl ml-2 inline-block" />
            </span>
          </div>
        </Link>
        <Grid products={products} loading={loading} numProducts={8} />
      </div>
    </section>
  );
};

const DiscountedProducts = ({
  scrollTop,
}: {
  scrollTop: MutableRefObject<number>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    setLoading(true);
    const fetchDiscountedProducts = async () => {
      let q = query(
        collection(db, 'products'),
        where('discount', '>', 0),
        where('sold', '==', false),
        orderBy('createdAt', 'desc'),
        limit(8)
      );
      const querySnapshot = await getDocs(q);
      const products = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as Product[];

      setProducts(products);
      setLoading(false);
    };

    fetchDiscountedProducts();
  }, []);

  return (
    <section className="bg-background py-8">
      <div className="container mx-auto px-4">
        <Link to="/products?discounted=true" reloadDocument>
          <div className="flex justify-between px-4 items-center">
            <h2 className="text-3xl font-bold font-serif mb-4">On Sale</h2>
            <span className="flex items-center mb-4">
              <h2 className="text-lg font-bold font-serif">View all</h2>
              <HiArrowLongRight className="text-xl ml-2 inline-block" />
            </span>
          </div>
        </Link>
        <Grid products={products} loading={loading} numProducts={8} />
      </div>
    </section>
  );
};

const ScrollingBrands = () => {
  const brands = [
    'Gucci',
    'Prada',
    'Louis Vuitton',
    'Chanel',
    'Versace',
    'Dior',
    'Fendi',
    'Hermès',
    'Balenciaga',
    'Burberry',
  ];

  return (
    <div className="relative flex bg-white border-gray-200 py-2 overflow-hidden">
      <div className="whitespace-nowrap animate-marquee">
        {brands.concat(brands).map((brand, index) => (
          <span key={index} className="text-gray-500 font-serif text-2xl mx-8">
            {brand}
          </span>
        ))}
      </div>
      <div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap">
        {brands.concat(brands).map((brand, index) => (
          <span key={index} className="text-gray-500 font-serif text-2xl mx-8">
            {brand}
          </span>
        ))}
      </div>
    </div>
  );
};

const LandingPage = () => {
  const { scrollTop } = useOutletContext<OutletContext>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [productCount, setProductCount] = useState<number>(10000);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductCount = async () => {
      const q = query(collection(db, 'products'), where('sold', '==', false));
      const snapshot = await getCountFromServer(q);
      setProductCount(snapshot.data().count);
    };

    fetchProductCount();
  }, []);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchInput.trim()) {
      navigate(`/products?search=${encodeURIComponent(searchInput.trim())}`);
    }
  };

  const searchExamples = [
    'Louis Vuitton Neverfull',
    'Chanel Classic Flap',
    'Hermès Birkin',
    'Gucci Marmont',
  ];

  const placeholderText = useTypingEffect(searchExamples);

  return (
    <div>
      <div
        className="relative aspect-auto sm:aspect-auto sm:h-96 bg-cover bg-center"
        style={{ backgroundImage: "url('/landing.jpg')" }}
      >
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="relative z-10 flex flex-col justify-between h-full text-center text-white px-4 py-8">
          <h1 className="font-serif text-4xl sm:text-5xl font-bold mt-24">
            Designer Bags <br />
            search engine
          </h1>
          <h2 className="font-serif text-2xl sm:text-4xl mb-4">
            Compare <Number value={productCount} /> vintage bags from trusted
            shops
          </h2>
          <div className="flex flex-col items-center space-y-4">
            <Link
              to="/products"
              className="inline-block"
              id="landing-start-comparing-button"
            >
              <div className="bg-transparent border border-white text-white rounded-none p-2 px-4 font-sans">
                Start comparing
              </div>
            </Link>
          </div>
        </div>
      </div>

      <section className="bg-background py-8">
        <div className="container mx-auto px-8">
          <div className="flex flex-col items-center space-y-4">
            <form onSubmit={handleSearch} className="w-full max-w-md">
              <div className="flex relative items-center mb-2 w-full mx-auto">
                <input
                  type="text"
                  value={searchInput}
                  onChange={e => setSearchInput(e.target.value)}
                  placeholder={placeholderText}
                  className="data-hj-allow rounded-full p-1 font-sans  shadow px-4 w-full border border-black text-black outline-none bg-white"
                />
                {searchInput ? (
                  <LuSearchX
                    onClick={() => setSearchInput('')}
                    className="absolute right-3 cursor-pointer text-black"
                    size={20}
                  />
                ) : (
                  <LuSearch
                    className="absolute right-3 cursor-pointer text-black"
                    size={20}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </section>

      <BestDealsProducts scrollTop={scrollTop} />

      <LatestProducts scrollTop={scrollTop} />

      <div className="relative w-full h-72">
        <img
          src="/landing02.jpg"
          alt="Description of the image"
          className="w-full h-full object-cover brightness-50"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center space-y-2 p-4">
          <p className="text-md pb-6 text-white font-serif text-4xl text-center">
            Explore <Number value={productCount} /> products
          </p>
          <Link
            to="/products"
            id="landing-see-all-products-button"
            reloadDocument
          >
            <div className="bg-transparent border border-white text-white rounded-none p-2 px-4 font-sans">
              See all products
            </div>
          </Link>
        </div>
      </div>

      <DiscountedProducts scrollTop={scrollTop} />
    </div>
  );
};

export default LandingPage;
