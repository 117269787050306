import { useEffect, useRef, useState } from "react";
import { db } from "../lib/firebase";
import {
  collection,
  getDocs,
  limit,
  query as firestoreQuery,
  where,
} from "firebase/firestore";
import { Product } from "../lib/types";
import Grid from "../components/products/Grid";
import FilterMenu from "../components/FilterMenu";
import {
  useNavigationType,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { categoryMap } from "../lib/types";
import FilterBar from "../components/FilterBar";
import Pagination from "../components/Pagination";
import Search from "../components/products/Search";
import {
  createQueryFromSearchParams,
  createSearchParamsFromQuery,
  defaultQuery,
  Query,
  search,
} from "../lib/search";
import FilterButton from "../components/products/FilterButton";
import SortDropdown from "../components/products/SortDropdown";
import FilterPills from "../components/products/FilterPills";
import { OutletContext } from "../App";
import DesktopFilterMenu from "../components/DesktopFilterMenu";

export default function ProductsPage() {
  const { scrollTop, setSelectedProducts } = useOutletContext<OutletContext>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigationType = useNavigationType();
  const [back, setBack] = useState<boolean>(false);

  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<Query>(defaultQuery);
  const productsPerPage = 30;

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const [totalPages, setTotalPages] = useState<number>(0);

  const [searchInput, setSearchInput] = useState<string>("");
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const isMounted = useRef(false);

  function logIfLocalhost(message: string) {
    if (window.location.hostname === "localhost") {
      console.log(message);
    }
  }

  const fetchData = async (query: Query) => {
    setProducts([]);
    setLoading(true);
    const { hits: ids, totalPages } = await search(
      query,
      query.page,
      productsPerPage
    );
    if (ids.length === 0) {
      setLoading(false);
      return;
    }

    const productsQuery = firestoreQuery(
      collection(db, "products"),
      where("__name__", "in", ids),
      limit(productsPerPage)
    );
    const snapshot = await getDocs(productsQuery);
    const products = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Product[];

    if (query.sortBy) {
      switch (query.sortBy.value) {
        case "priceAsc":
          products.sort((a: Product, b: Product) => a.sortPrice - b.sortPrice);
          break;
        case "priceDesc":
          products.sort((a: Product, b: Product) => b.sortPrice - a.sortPrice);
          break;
        case "priceDiff":
          products.sort(
            (a: Product, b: Product) =>
              a.percentageDifference - b.percentageDifference
          );
          break;
        case "conditionAsc":
          products.sort((a: Product, b: Product) => a.quality - b.quality);
          break;
        case "conditionDesc":
          products.sort((a: Product, b: Product) => b.quality - a.quality);
          break;
        case "brand":
          products.sort((a: Product, b: Product) =>
            a.brand.localeCompare(b.brand)
          );
          break;
        case "createdAt":
          products.sort(
            (a: Product, b: Product) =>
              b.createdAt.toMillis() - a.createdAt.toMillis()
          );
          break;
        case "discount":
          products.sort(
            (a: Product, b: Product) =>
              b.discountPercentage - a.discountPercentage
          );
          break;
      }
    }

    setProducts(products);
    setTotalPages(totalPages);
    setLoading(false);
  };

  useEffect(() => {
    logIfLocalhost("mounting begin");
    if (navigationType === "POP") setBack(true);
    logIfLocalhost(`searchParams: ${searchParams}`);
    const query = createQueryFromSearchParams(searchParams);
    setQuery(query);
    setSearchInput(query.search);
    fetchData(query);
    return () => {
      logIfLocalhost("unmounting");
    };
  }, []);
  useEffect(() => {
    logIfLocalhost(`Query changed: ${JSON.stringify(query)}`);

    if (!isMounted.current) {
      logIfLocalhost("tried to update search params");
      return;
    }

    logIfLocalhost("updated search params and fetch data");

    const params = createSearchParamsFromQuery(query);
    setSearchParams(params, { replace: true });
    fetchData(query);
  }, [query]);

  useEffect(() => {
    if (!isMounted.current) {
      logIfLocalhost("tried to fetch data from search field");
      return;
    }

    logIfLocalhost("fetch data from search field");

    if (searchInput === "") {
      setQuery({ ...query, search: searchInput });
      fetchData(query);
    } else {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        if (searchInput) {
          setQuery({ ...query, search: searchInput });
        }
      }, 1000);
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchInput]);

  useEffect(() => {
    logIfLocalhost("mounted done");
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (!loading) {
      if (back) {
        logIfLocalhost("scrolling to last product");
        logIfLocalhost(`scrollTop: ${scrollTop.current}`);
        window.scrollTo(0, scrollTop.current);
        setBack(false);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [loading, back]);

  const handlePageChange = (page: number) => {
    setQuery({ ...query, page: page });
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: page.toString(),
    });
  };

  return (
    <div className={`min-h-screen flex flex-col pb-16`}>
      <main className="flex-grow p-4 container mx-auto">
        <Search searchInput={searchInput} setSearchInput={setSearchInput} />

        <h1 className="text-3xl font-serif mt-4 mb-2 text-center">
          {query.category ? categoryMap[query.category.value] : "All Products"}
        </h1>

        <div className="flex flex-row gap-8">
          <div className="hidden lg:block sticky top-4 h-fit">
            <DesktopFilterMenu query={query} setQuery={setQuery} />
          </div>

          <div className="flex-1">
            <div className="flex flex-row mb-2 items-center justify-between">
              <div className="lg:hidden">
                <FilterButton toggleFilter={toggleFilter} />
              </div>
              <div className="ml-auto">
                <SortDropdown
                  label={query.sortBy?.label}
                  query={query}
                  setQuery={setQuery}
                />
              </div>
            </div>

            <div className="lg:hidden">
              <FilterMenu
                query={query}
                setQuery={setQuery}
                isOpen={isFilterOpen}
                close={() => setIsFilterOpen(false)}
              />
            </div>

            <div className="mb-4 lg:hidden">
              <FilterPills query={query} setQuery={setQuery} />
            </div>

            {!loading && products.length === 0 ? (
              <div className="flex flex-col justify-center items-center h-64 text-center">
                <p className="font-sans text-lg text-gray-600 mb-2">
                  No products found
                </p>
                <p className="font-sans text-sm text-gray-500">
                  Try adjusting your filters or search terms
                </p>
              </div>
            ) : (
              <>
                <Grid
                  products={products}
                  loading={loading}
                  numProducts={productsPerPage}
                />
                <Pagination
                  totalPages={totalPages}
                  currentPage={query.page}
                  onPageChange={handlePageChange}
                />
                {!loading && products.length > 0 && (
                  <div className="flex justify-center items-center mt-4">
                    <span className="mr-2 text-sm">Powered by</span>
                    <img
                      src="/algolia.png"
                      alt="Powered by Algolia"
                      width={30}
                      height={30}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
