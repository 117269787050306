import { useEffect, useState } from "react";
import Select, {
  MultiValue,
  OptionProps,
  SingleValue,
  components,
} from "react-select";
import { brandMap, categoryOptions, conditionMap } from "../lib/types";
import { Option } from "../lib/types";
import { Query } from "../lib/search";

const OptionComponent = (props: OptionProps<Option>) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="accent-black"
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

interface Props {
  query: Query;
  setQuery: (query: Query) => void;
}

const DesktopFilterMenu = ({ query, setQuery }: Props) => {
  const [categoryFilter, setCategoryFilter] =
    useState<SingleValue<Option> | null>(query.category);
  const [brandsFilter, setBrandsFilter] = useState<MultiValue<Option>>(
    query.brands
  );
  const [conditionsFilter, setConditionsFilter] = useState<MultiValue<Option>>(
    query.conditions
  );
  const [minPriceFilter, setMinPriceFilter] = useState<string>(query.minPrice);
  const [maxPriceFilter, setMaxPriceFilter] = useState<string>(query.maxPrice);
  const [isDiscountedFilter, setIsDiscountedFilter] = useState<boolean>(
    query.isDiscounted
  );

  useEffect(() => {
    setCategoryFilter(query.category);
    setBrandsFilter(query.brands);
    setConditionsFilter(query.conditions);
    setMinPriceFilter(query.minPrice);
    setMaxPriceFilter(query.maxPrice);
    setIsDiscountedFilter(query.isDiscounted);
  }, [query]);

  const handleCategoryChange = (selectedOption: SingleValue<Option> | null) => {
    setCategoryFilter(selectedOption);
    setQuery({ ...query, category: selectedOption, page: 1 });
  };

  const handleBrandsChange = (selectedOptions: MultiValue<Option>) => {
    setBrandsFilter(selectedOptions);
    setQuery({ ...query, brands: selectedOptions, page: 1 });
  };

  const handleConditionsChange = (selectedOptions: MultiValue<Option>) => {
    setConditionsFilter(selectedOptions);
    setQuery({ ...query, conditions: selectedOptions, page: 1 });
  };

  const handleMinPriceChange = (value: string) => {
    setMinPriceFilter(value);
    setQuery({ ...query, minPrice: value, page: 1 });
  };

  const handleMaxPriceChange = (value: string) => {
    setMaxPriceFilter(value);
    setQuery({ ...query, maxPrice: value, page: 1 });
  };

  const handleIsDiscountedChange = () => {
    const newValue = !isDiscountedFilter;
    setIsDiscountedFilter(newValue);
    setQuery({ ...query, isDiscounted: newValue, page: 1 });
  };

  function clearAll() {
    setCategoryFilter(null);
    setBrandsFilter([]);
    setConditionsFilter([]);
    setMinPriceFilter("");
    setMaxPriceFilter("");
    setIsDiscountedFilter(false);

    setQuery({
      ...query,
      page: 1,
      category: null,
      brands: [],
      conditions: [],
      minPrice: "",
      maxPrice: "",
      isDiscounted: false,
    });
  }

  return (
    <div className="w-64 pr-8">
      <h2 className="text-xl font-serif font-semibold mb-4">Filter</h2>

      <div className="mb-4">
        <Select
          value={categoryFilter}
          onChange={handleCategoryChange}
          options={categoryOptions}
          placeholder={"Category"}
          className="font-sans"
          isSearchable={false}
          isClearable
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#ccc",
              primary: "black",
            },
          })}
        />
      </div>

      <div className="mb-4">
        <Select
          value={brandsFilter}
          onChange={handleBrandsChange}
          options={brandMap}
          placeholder={"Brand"}
          className="font-sans"
          isClearable
          isSearchable
          isMulti
          blurInputOnSelect={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option: OptionComponent }}
          styles={{
            option: (base) => ({
              margin: "0.5rem",
            }),
          }}
        />
      </div>

      <div className="mb-4">
        <Select
          value={conditionsFilter}
          onChange={handleConditionsChange}
          options={conditionMap}
          placeholder={"Condition"}
          className="font-sans"
          isSearchable={false}
          isClearable
          isMulti
          blurInputOnSelect={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option: OptionComponent }}
          styles={{
            option: (base) => ({
              margin: "0.5rem",
            }),
          }}
        />
      </div>

      <div className="mb-4">
        <input
          id="maxPrice"
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          value={maxPriceFilter}
          onChange={(e) => handleMaxPriceChange(e.target.value)}
          placeholder="Max price"
          className="w-full border border-gray-300 rounded-md p-2"
        />
      </div>

      <div className="mb-4">
        <input
          id="minPrice"
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          value={minPriceFilter}
          onChange={(e) => handleMinPriceChange(e.target.value)}
          placeholder="Min price"
          className="w-full border border-gray-300 rounded-md p-2"
        />
      </div>

      <div className="mb-4 font-sans">
        <div
          className="flex items-center mb-4 cursor-pointer"
          onClick={handleIsDiscountedChange}
        >
          <input
            id="discounted"
            type="checkbox"
            checked={isDiscountedFilter}
            onChange={() => null}
            className="mr-2 cursor-pointer accent-black"
          />{" "}
          <label className="cursor-pointer">On Sale</label>
        </div>
      </div>

      <button
        className="w-full border border-black py-2 font-sans mt-2"
        onClick={clearAll}
      >
        Clear All
      </button>
    </div>
  );
};

export default DesktopFilterMenu;
