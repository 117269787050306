import React, { LegacyRef, MutableRefObject } from "react";
import Card from "../Card";
import { Product } from "../../lib/types";
import { Link, useOutletContext } from "react-router-dom";
import { OutletContext } from "../../App";
import GridPlaceholder from "./GridPlaceholder";

interface Props {
  products: Product[];
  loading: boolean;
  numProducts: number;
  reloadDocument?: boolean;
}

const Grid = ({ products, loading, numProducts, reloadDocument }: Props) => {
  const { scrollTop, selectedProducts, handleSelectProduct } =
    useOutletContext<OutletContext>();

  return (
    <div>
      {loading ? (
        <GridPlaceholder numProducts={numProducts} />
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
          {products.map((product) => (
            <Link
              key={product.id}
              to={`/product/${product.id}`}
              onClick={() => (scrollTop.current = window.scrollY)}
              reloadDocument={reloadDocument ?? false}
              className="p-1"
            >
              <Card
                product={product}
                handleSelectProduct={handleSelectProduct}
                isSelected={selectedProducts.some((p) => p.id === product.id)}
              />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Grid;
