import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const CookieBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('ga-consent');
    if (consent === null || consent === 'false') {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('ga-consent', 'true');
    setIsVisible(false);
    initializeGA();
  };

  const handleReject = () => {
    localStorage.setItem('ga-consent', 'false');
    setIsVisible(false);
  };

  const initializeGA = () => {
    // Call the function defined in index.html
    (window as any).initializeGTM();
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-[0_-2px_10px_rgba(0,0,0,0.1)] z-50 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
      <p className="text-sm sm:text-base">
        We use cookies to enhance your experience and analyze site traffic. By
        clicking "Accept", you consent to{' '}
        <Link
          to="https://blog.avenura.com/cookie-policy/"
          className="underline"
        >
          our use of cookies
        </Link>
        .
      </p>
      <div className="flex space-x-4">
        <button
          onClick={handleAccept}
          className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 transition-colors duration-300"
        >
          Accept
        </button>
        <button
          onClick={handleReject}
          className="px-4 py-2 bg-white text-black border border-black rounded hover:bg-gray-200 transition-colors duration-300"
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
