import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import { Product } from "./lib/types";
import Compare from "./components/Compare";
import CompareFooter from "./components/CompareFooter";
import ScrollToTop from "./components/ScrollToTop";
import CookieBanner from "./components/CookieBanner";

export type OutletContext = {
  scrollTop: React.MutableRefObject<number>;
  selectedProducts: Product[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  handleSelectProduct: (product: Product) => void;
  isComparing: boolean;
  setNumLikedProducts: (num: number) => void;
};

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();
  const isLandingPage = location.pathname === "/";
  const isProductsPage = location.pathname === "/products";

  const scrollTop = useRef<number>(0);

  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [showCompare, setShowCompare] = useState<boolean>(false);
  const isComparing = selectedProducts.length > 0;
  const canCompare = selectedProducts.length > 1;

  const handleSelectProduct = (product: Product) => {
    setSelectedProducts((prev) => {
      if (prev.some((p) => p.id === product.id)) {
        return prev.filter((p) => p.id !== product.id);
      } else {
        return [...prev, product];
      }
    });
  };

  const [numLikedProducts, setNumLikedProducts] = useState(0);

  useEffect(() => {
    const updateLikedProductsCount = () => {
      const likedProducts =
        JSON.parse(localStorage.getItem("likedProducts") || "[]") || [];
      setNumLikedProducts(likedProducts.length);
    };
    updateLikedProductsCount();
  }, []);

  return (
    <div>
      <Header toggleMenu={toggleMenu} numLikedProducts={numLikedProducts} />

      <div className={`${isLandingPage ? "" : "pt-24"} bg-background `}>
        <Outlet
          context={
            {
              scrollTop: scrollTop,
              selectedProducts: selectedProducts,
              setSelectedProducts: setSelectedProducts,
              handleSelectProduct: handleSelectProduct,
              isComparing: selectedProducts.length > 0,
              setNumLikedProducts: setNumLikedProducts,
            } satisfies OutletContext
          }
        />
      </div>

      <Menu isOpen={isMenuOpen} toggleMenu={toggleMenu} />

      {showCompare && (
        <Compare
          products={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          handleClose={() => {
            setShowCompare(false);
          }}
        />
      )}

      {isComparing && (
        <CompareFooter
          selectedProducts={selectedProducts.length}
          canStartComparing={canCompare}
          startComparing={() => setShowCompare(true)}
          clearSelectedProducts={() => setSelectedProducts([])}
        />
      )}

      {isProductsPage ? <ScrollToTop isComparing={isComparing} /> : null}

      <CookieBanner />

      <Footer />
    </div>
  );
};

export default App;
