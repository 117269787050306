import React from 'react';

interface Props {
  amount: number;
  currency: string;
}

export default function Price({ amount, currency }: Props) {
  const locale = navigator.language;

  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || 'SEK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);

  return <span>{formattedPrice}</span>;
}
