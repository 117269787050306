import React, { ReactNode, useState } from 'react';
import { RiInstagramLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

interface FooterSectionProps {
  title: string;
  children: ReactNode;
}

const FooterSection: React.FC<FooterSectionProps> = ({ title, children }) => {
  return (
    <div>
      <h3 className="text-md uppercase">{title}</h3>
      <div className="h-[1px] bg-black mt-2 mb-4 w-32"></div>
      <div className="text-sm leading-loose">{children}</div>
    </div>
  );
};

const Footer = () => {
  const [email, setEmail] = useState<string>('');

  return (
    <footer className="bg-white py-8 px-4 pb-24">
      <div className="container mx-auto grid grid-cols-2 md:grid-cols-4 gap-8 text-black p-4">
        <div className="col-span-1">
          <FooterSection title="Company">
            <Link to="https://blog.avenura.com/about">
              <div>About Us</div>
            </Link>
            <Link to="https://blog.avenura.com">
              <div>Blog</div>
            </Link>
            <Link to="https://blog.avenura.com/partners">
              <div>Partners</div>
            </Link>
          </FooterSection>
        </div>

        <div className="col-span-1">
          <FooterSection title="Legal">
            <Link to="https://blog.avenura.com/privacy-policy">
              <div>Privacy Policy</div>
            </Link>
            <Link to="https://blog.avenura.com/cookie-policy/">
              <div>Cookie Policy</div>
            </Link>
            <Link to="https://blog.avenura.com/terms-conditions">
              <div>Terms of Service</div>
            </Link>
          </FooterSection>
        </div>

        <div className="col-span-2 md:col-span-1">
          <FooterSection title="Newsletter">
            <p>Stay in the know</p>
            <form
              action="https://avenura.us14.list-manage.com/subscribe/post?u=8508384ba9ada26333746aa5e&amp;id=e03e7011b8&amp;f_id=000b89e1f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_self"
              noValidate
            >
              <div className="flex space-x-2">
                <input
                  placeholder="Enter your email"
                  type="email"
                  name="EMAIL"
                  className="flex-grow w-full mt-2 p-2 md:w-auto rounded border"
                  id="mce-EMAIL"
                  required
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                />
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="mt-2 rounded-md p-2 font-sans bg-black text-white"
                  value="Subscribe"
                  onChange={() => {}}
                />
                <div hidden>
                  <input type="hidden" name="tags" value="7075557" />
                </div>
                <div id="mce-responses" className="clear"></div>
                <div
                  aria-hidden="true"
                  className="absolute -translate-x-[5000px]"
                >
                  <input
                    type="text"
                    name="b_8508384ba9ada26333746aa5e_e03e7011b8"
                    tabIndex={-1}
                    value=""
                    onChange={() => {}}
                  />
                </div>
              </div>
            </form>
          </FooterSection>
        </div>

        <div className="col-span-2 md:col-span-1">
          <FooterSection title="Follow Us">
            <div className="flex space-x-4">
              <a
                href="https://www.instagram.com/_avenura.com_"
                aria-label="Instagram"
                id="footer-instagram-link"
              >
                <RiInstagramLine size={24} />
              </a>
            </div>
          </FooterSection>
        </div>
      </div>
      <div className="text-center mt-4">
        <p className="text-xs">Avenura AB Orgnr. 559494-9454</p>
      </div>
    </footer>
  );
};

export default Footer;
