import React from "react";
import { RiStarFill, RiStarLine } from "react-icons/ri";
import { Product } from "../lib/types";
import ClickPropagationBlocker from "./common/ClickPropagationBlocker";

interface Props {
  product: Product;
}

export default function PriceRating({ product }: Props) {
  const priceDifference = product.sortPrice - product.averagePrice;
  const percentageDifference = (priceDifference / product.averagePrice) * 100;

  const getFilledStars = () => {
    if (percentageDifference <= -20) return 5;
    if (percentageDifference <= -5) return 4;
    if (Math.abs(percentageDifference) <= 5) return 3;
    if (percentageDifference <= 20) return 2;
    return 1;
  };

  const filledStars = getFilledStars();

  const getRatingLabel = (filledStars: number) => {
    switch (filledStars) {
      case 5:
        return "Excellent Value";
      case 4:
        return "Great Deal";
      case 3:
        return "Fair Price";
      case 2:
        return "Pricey";
      case 1:
        return "Expensive";
      default:
        return "Unknown";
    }
  };

  const isAboveAverage = percentageDifference > 0;
  const comparisonText = isAboveAverage ? "above" : "below";
  const hasUnknownCondition = !product.condition;
  const hasNoPriceRatingYet =
    isNaN(percentageDifference) && !hasUnknownCondition;

  if (hasNoPriceRatingYet) return null;

  return (
    <>
      {hasUnknownCondition ? (
        <ClickPropagationBlocker>
          <div className="flex items-center mt-2 text-xs">
            <span className="mr-2">No price rating available</span>
          </div>
        </ClickPropagationBlocker>
      ) : (
        <div className={`flex flex-col mt-2 text-xs rounded-full space-y-1`}>
          <div className="flex items-center">
            <span className="mr-2 font-semibold">
              {getRatingLabel(filledStars)}
            </span>
            {[...Array(5)].map((_, index) => (
              <span key={index} className="text-black">
                {index < filledStars ? <RiStarFill /> : <RiStarLine />}
              </span>
            ))}
          </div>
          <div className="flex">
            <span
              className={`inline-flex bg-accentLight text-accentDark rounded-full px-2 py-1 text-xs font-semibold`}
            >
              {Math.abs(percentageDifference).toFixed(0)}% {comparisonText} avg
              price
            </span>
          </div>
        </div>
      )}
    </>
  );
}
