import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { IoMdClose } from 'react-icons/io';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

interface Props {
  images: string[];
  children?: ReactNode;
}

export default function Carousel({ images, children }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [deltaX, setDeltaX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null);
  const [isPinching, setIsPinching] = useState(false);

  const handlePinchStart = useCallback(() => {
    setIsPinching(true);
  }, []);

  const handlePinchEnd = useCallback(() => {
    setIsPinching(false);
  }, []);

  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const handleSwiping = (data: { deltaX: number }) => {
    if (!isTransitioning) {
      setIsSwiping(true);
      setDeltaX(data.deltaX);
    }
  };

  const handleSwiped = (data: { velocity: number }) => {
    if (data.velocity > 0.3 || Math.abs(deltaX) > 50) {
      if (deltaX < 0) {
        goToNext();
      } else {
        goToPrevious();
      }
    } else {
      resetPosition();
    }
    setIsSwiping(false);
  };

  const resetPosition = () => {
    setDeltaX(0);
    setIsTransitioning(true);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  const goToNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    setDeltaX(0);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  const goToPrevious = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex(
      prevIndex => (prevIndex - 1 + images.length) % images.length
    );
    setDeltaX(0);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  const goToIndex = (index: number) => {
    if (isTransitioning) return;
    setCurrentIndex(index);
    setDeltaX(0);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  const handlers = useSwipeable({
    onSwiping: eventData => handleSwiping(eventData),
    onSwiped: eventData => handleSwiped(eventData),
    preventScrollOnSwipe: true,
  });

  const openFullscreen = (image: string) => {
    if (!isDesktop) {
      setFullscreenImage(image);
    }
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  useEffect(() => {
    const preventDefault = (e: TouchEvent) => {
      if (e.touches.length > 1 && isPinching) {
        e.preventDefault();
      }
    };

    const disableZoom = () => {
      document.addEventListener('touchmove', preventDefault, {
        passive: false,
      });
    };

    const enableZoom = () => {
      document.removeEventListener('touchmove', preventDefault);
    };

    if (fullscreenImage) {
      disableZoom();
    }

    return () => {
      enableZoom();
    };
  }, [fullscreenImage, isPinching]);

  return (
    <>
      <div
        className="relative aspect-square w-full mx-auto overflow-hidden"
        {...handlers}
      >
        <div className="relative">
          <div
            className={`relative flex ${
              !isSwiping ? 'transition-transform duration-300 ease-in-out' : ''
            }`}
            style={{
              transform: `translateX(calc(-${
                currentIndex * 100
              }% + ${deltaX}px))`,
            }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                className="w-full flex-shrink-0 object-cover aspect-square"
                onClick={() => openFullscreen(image)}
              />
            ))}
          </div>
          {!isDesktop && (
            <div className="absolute bottom-4 w-full flex justify-center space-x-2">
              {images.map((_, index) => (
                <span
                  key={index}
                  className={`w-2 h-2 rounded-full cursor-pointer ${
                    index === currentIndex
                      ? 'bg-black'
                      : 'bg-white border-black border'
                  }`}
                  onClick={() => goToIndex(index)}
                ></span>
              ))}
            </div>
          )}
          {children}
        </div>

        <button
          className="absolute border border-black left-4 top-1/2 transform -translate-y-1/2 bg-white text-black rounded-full p-2"
          onClick={goToPrevious}
          disabled={isTransitioning}
        >
          <RiArrowLeftSLine className="text-md" />
        </button>
        <button
          className="absolute border border-black right-4 top-1/2 transform -translate-y-1/2 bg-white text-black rounded-full p-2"
          onClick={goToNext}
          disabled={isTransitioning}
        >
          <RiArrowRightSLine className="text-md" />
        </button>

        {isDesktop && (
          <div className="mt-4 flex justify-center space-x-2">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                className={`w-16 h-16 object-cover cursor-pointer ${
                  index === currentIndex
                    ? 'border-2 border-black'
                    : 'border-2 border-transparent'
                }`}
                onClick={() => goToIndex(index)}
              />
            ))}
          </div>
        )}
      </div>

      {fullscreenImage && (
        <div className="fixed inset-0 z-50 bg-background touch-none">
          <TransformWrapper
            initialScale={1}
            minScale={1}
            maxScale={3}
            centerOnInit={true}
            onPinchingStart={handlePinchStart}
            onPinchingStop={handlePinchEnd}
            doubleClick={{ disabled: true }}
            panning={{ velocityDisabled: true }}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <TransformComponent
                wrapperStyle={{
                  width: '100%',
                  height: '100%',
                }}
                contentStyle={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <img
                  src={fullscreenImage}
                  alt="Fullscreen"
                  className="w-full h-full object-contain"
                  draggable="false"
                />
              </TransformComponent>
            )}
          </TransformWrapper>
          <button
            className="absolute top-4 right-4 bg-white text-black px-3 py-2 rounded-full border-black border flex items-center space-x-2 z-60"
            onClick={closeFullscreen}
          >
            <IoMdClose size={24} />
            <span>Close</span>
          </button>
        </div>
      )}
    </>
  );
}
