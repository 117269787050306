import React from "react";

interface Props {
  label: string;
  onRemove: () => void;
}

const FilterPill = ({ label, onRemove }: Props) => {
  return (
    <div className="inline-block bg-accentLight text-accentDark px-3 py-1 rounded-full mr-2">
      {label}
      <button className="ml-2" onClick={onRemove}>
        &times;
      </button>
    </div>
  );
};

export default FilterPill;
